import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "../components/index.module.css"
import {graphql} from "gatsby";

const title = 'Your document management system - Create, report, store';
const description = 'Create, report, and store documents while uncovering hidden pain points in your workflow and optimizing every step of your company\'s work funnel.'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
const IndexPage = () => {
    return(
        <Layout location={'/'}>

            <Seo description={description} title={title}/>
        </Layout>
    )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo description={description} title={title} />

export default IndexPage;
