/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

function Seo({description, title, children}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
            image
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const keywords=site.siteMetadata.keywords;
    const siteImage=`${site.siteMetadata.siteUrl}${site.siteMetadata.image}`

    return (
        <>
            <title>{defaultTitle ? `${defaultTitle} | ${title}` : title}</title>
            <meta name="title" content={title || defaultTitle} />
            <meta name="description" content={metaDescription}/>
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={metaDescription}/>
            <meta property="og:type" content="website"/>
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:creator" content={site.siteMetadata?.author || ``}/>
            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={metaDescription}/>
            <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
            <meta property="twitter:keywords" content={keywords} />
            <meta property="og:keywords" content={keywords} />
            <meta name="image" content={siteImage} />
            <meta property="twitter:url" content={site.siteMetadata.siteUrl} />
            <meta property="twitter:image" content={siteImage} />
            <meta property="og:url" content={site.siteMetadata.siteUrl} />
            <meta property="og:image" content={siteImage} />

            <script type="application/ld+json" dangerouslySetInnerHTML={{
                __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "Corporation",
                  "name": "Doxio",
                  "alternateName": "Document management system",
                  "url": "https://doxio.online",
                  "logo": "https://doxio.online/static/8a49ae9b66bd76031a8f1f5b835ef831/f9319/logo-small.webp",
                  "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "+382 20 223 244",
                    "contactType": "technical support",
                    "contactOption": ["HearingImpairedSupported","TollFree"],
                    "areaServed": "ME",
                    "availableLanguage": "en"
                  },
                  "sameAs": [
                    "https://www.facebook.com/doxio.online",
                    "https://instagram.com/doxio.online?igshid=NTc4MTIwNjQ2YQ==",
                    "https://www.linkedin.com/company/doxio-document-management-system/"
                  ]
                }
            `,}} />

            <script type="application/ld+json" dangerouslySetInnerHTML={{
                __html: `
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        {
                            "@type": "Question",
                            "name": "What is a document management system (DMS) and what are its benefits?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A document management system (DMS) is software that simplifies document organization, storage and retrieval. It centralizes document management, boosting efficiency, productivity and collaboration among team members. Benefits include easy access to information, reduced paper clutter, streamlined workflows, version control and seamless collaboration."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "How does a document management system ensure document security and confidentiality?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Document management systems ensure document security through access controls, encryption, audit trails and backups. Only authorized users can access sensitive documents, data is protected during storage and transmission and activity is tracked for monitoring."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Can a DMS handle different types of documents?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, document management systems are designed to handle various document types, including PDFs, images and scanned paper documents. They provide a centralized repository where you can store, organize and access these different file formats, making it easy to manage and retrieve documents as needed."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Can a DMS help with compliance and regulatory requirements?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Absolutely! DMS offers features like record retention policies, ensuring documents are retained for the required duration. Additionally, document management systems facilitate adherence to data privacy regulations by implementing access controls, encryption and audit trails to protect sensitive information. These features help organizations meet compliance obligations and maintain data integrity and security."
                            }
                        }
                    ]
                }
            `,}} />

            {children}
        </>
    )
}

Seo.defaultProps = {
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default Seo
